exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-body-body-jsx": () => import("./../../../src/pages/components/Body/Body.jsx" /* webpackChunkName: "component---src-pages-components-body-body-jsx" */),
  "component---src-pages-components-clients-clients-jsx": () => import("./../../../src/pages/components/Clients/Clients.jsx" /* webpackChunkName: "component---src-pages-components-clients-clients-jsx" */),
  "component---src-pages-components-contact-contact-jsx": () => import("./../../../src/pages/components/Contact/Contact.jsx" /* webpackChunkName: "component---src-pages-components-contact-contact-jsx" */),
  "component---src-pages-components-faq-faq-accordion-jsx": () => import("./../../../src/pages/components/FAQ/FAQAccordion.jsx" /* webpackChunkName: "component---src-pages-components-faq-faq-accordion-jsx" */),
  "component---src-pages-components-faq-faq-jsx": () => import("./../../../src/pages/components/FAQ/FAQ.jsx" /* webpackChunkName: "component---src-pages-components-faq-faq-jsx" */),
  "component---src-pages-components-footer-footer-jsx": () => import("./../../../src/pages/components/Footer/Footer.jsx" /* webpackChunkName: "component---src-pages-components-footer-footer-jsx" */),
  "component---src-pages-components-header-header-jsx": () => import("./../../../src/pages/components/Header/Header.jsx" /* webpackChunkName: "component---src-pages-components-header-header-jsx" */),
  "component---src-pages-components-layout-layout-jsx": () => import("./../../../src/pages/components/Layout/Layout.jsx" /* webpackChunkName: "component---src-pages-components-layout-layout-jsx" */),
  "component---src-pages-components-navbar-navbar-jsx": () => import("./../../../src/pages/components/Navbar/Navbar.jsx" /* webpackChunkName: "component---src-pages-components-navbar-navbar-jsx" */),
  "component---src-pages-components-plans-plans-jsx": () => import("./../../../src/pages/components/Plans/Plans.jsx" /* webpackChunkName: "component---src-pages-components-plans-plans-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

